<template>
    <div class="container">
        <div class="text-center">
            <img src="@/assets/klos.svg" />
            <h2>CHLEBY</h2>
        </div>
        <div class="row justify-content-md-center mb-5">
            <div class="col-12 col-xl-12">
                <selectProducts ref="selectBreads" :group="groupBread" :products="breadList" :isSubscription="isSubscription()" />
            </div>
        </div>
        <div class="text-center pt-5">
            <img src="@/assets/klos.svg" />
            <h2>BUŁKI</h2>
        </div>
        <selectProducts ref="selectBuns" :group="groupBun" :products="bunsList"  :isSubscription="isSubscription()" />

        <div class="text-center pt-5">
            <img src="@/assets/klos.svg" />
            <h2>SŁODKIE WYPIEKI</h2>
        </div>
        <selectProducts ref="selectPastry" :group="groupPastry" :products="pastryList"  :isSubscription="isSubscription()" />


        <div class="container text-center mb-5">
            <b-alert v-if="invalidAmount" variant="danger" show>Wypełnij poprawnie ilość produktów</b-alert>
            
            <b-alert v-if=" $store.state.order.schedule.productTemplates.length == 0" variant="danger" show>Wybierz produkty do zamówienia</b-alert>
            <button v-else class="btn btn-lg btn-success" @click.prevent="checkProducts()">Dalej</button>
        </div>
    </div>
</template>

<script>

import SelectProducts from '@/components/order/SelectProducts.vue';
import {SCHEDULE_typeSubscription, PRODUCT_groupBread, PRODUCT_groupBun, PRODUCT_groupPastry, } from "@/constants/constants.js"; 

import { mapGetters } from "vuex";

export default {
    name: "productsStep",
    components: {
        SelectProducts,
    },
    computed: {
        ...mapGetters({isLoggedIn: 'auth/loggedIn', breadList: 'getBreads', bunsList: 'getBuns', pastryList: 'getPastries'}),
    },
    data() {
        return {
            groupBread: PRODUCT_groupBread,
            groupBun: PRODUCT_groupBun,
            groupPastry: PRODUCT_groupPastry,
            invalidAmount: false
        }
    },
    mounted(){
        if(!this.isLoggedIn) {
            this.$router.push('/login') 
        }
    },
    methods: {
        isSubscription( ) {
            return this.$store.state.order.schedule.scheduleType == SCHEDULE_typeSubscription
        },
        checkProducts( ){
            this.$store.dispatch('order/setProductsCompleted' ).then( () => {
                this.invalidAmount = false;
                this.$emit('nextStep', true);
            }).catch( () => {
                this.invalidAmount = true;
            });
        },
    }
};
</script>
