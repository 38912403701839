<template>
    <div class="container">
        <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
        <div v-else >
                <div class="row justify-content-md-center mb-5">
                    <div class="col-12 col-xl-8">
                        <b-card-group deck>
                            <b-card header-tag="header">
                                <template #header>
                                    <h3 class="text-center mb-0">systematyczne dostawy</h3>
                                </template>
                                <b-card-text>
                                    <ul>
                                       <li>dostawy od 3zł</li>
                                       <li>bezpieczna płatność</li>
                                       <li>bezpieczne dostawy bez kontaktu z kurierem</li>
                                       <li>sam wybierasz dni dostaw</li>
                                       <li>świeże pieczywo bez wychodzenia z domu</li>
                                       <li>dostawy przed 7:00</li>
                                       <li>płacisz raz, dostawy otrzymujesz w wybrane dni tygodnia</li>
                                       <li>sam wybierasz harmonogram dostaw</li>
                                       <li>oszczędzasz czas i pieniądze</li>
                                       <li>intuicyjny system zamówień</li>
                                       <li>dostęp do historii zamówień</li>
                                    </ul>
                                </b-card-text>
                                <template #footer>
                                    <center>
                                        <b-button variant="success"  @click.prevent="selectScheduleType(scheduleTypeSubscription)" >Wybieram</b-button>
                                    </center>
                                </template>
                            </b-card>
                            <b-card header-tag="header">
                                <template #header>
                                    <h3 class="text-center mb-0">jednorazowa dostawa</h3>
                                </template>
                                <b-card-text>
                                    <ul>
                                        <li>bezpieczna płatność</li>
                                        <li>świeże pieczywo bez wychodzenia z domu</li>
                                        <li>dostawa przed 7:30</li>
                                        <li>możliwość przetestowania naszych usług</li>
                                        <li>dostawa 7,50 zł</li>
                                    </ul>
                                </b-card-text>
                                <template #footer>
                                    <center>
                                        <b-button variant="success" @click.prevent="selectScheduleType(scheduleTypeOneTimePayment)" >Wybieram</b-button>
                                    </center>
                                </template>
                            </b-card>
                        </b-card-group>
                    </div>
                    
                </div>
                <!--
            <div v-if="!$store.state.order.completedStepScheduleType">            
            </div>
            <div v-else>
                <b-alert variant="success" show>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Rodzaj dostawy: <b>{{ scheduleTypeName }}</b></div>
                        <button class="btn btn-primary" @click.prevent="changeScheduleType">Zmień</button>
                    </div>
                </b-alert>
                <div class="container text-center mb-5">
                    <button class="btn btn-lg btn-success" @click.prevent="goToNextStep()">Dalej</button>
                </div>
            </div>
             -->
        </div>
    </div>
</template>

<script>

import {SCHEDULE_typeSubscription, SCHEDULE_typeOneTimePamyent } from "@/constants/constants.js"; 

export default {
    name: "scheduleTypeStep",
    components: {},
    data() {
        return {
            isLoading: false,
            scheduleTypeSubscription: SCHEDULE_typeSubscription,
            scheduleTypeOneTimePayment: SCHEDULE_typeOneTimePamyent,
        }
    },
    computed: {
        scheduleTypeName: function () {
            return this.$store.state.order.schedule.scheduleType == SCHEDULE_typeSubscription ? 'Abonament miesięczny' : 'Jednorazowa dostawa';
        },
    },
    methods: {
        goToNextStep() {
            this.$emit('nextStep', true);
        },
        selectScheduleType(type) {
            this.isLoading = true;
            this.$store.dispatch('order/updateScheduleType', type)
                .finally( () => {
                    this.$store.state.order.completedStepProducts = false;
                    this.$store.dispatch('order/updateDates', { start: null, end: null })
                        .finally( () => {
                            this.isLoading = false;
                            this.$emit('nextStep', true);
                        });
                });
        },
        changeScheduleType() {
            this.isLoading = true;
            this.$store.dispatch('order/updateScheduleType', null)
                .finally( () => {
                    this.isLoading = false;
                });
        }
    }
};
</script>
