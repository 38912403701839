<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="my-5 p-3">
                    <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
                    <div v-if="!isLoading">
                        <div v-if="!$store.state.order.completedStepDate" class="form-container" >
                            <form method="post" @submit.prevent="confirmDate" action="">
                                <div class="row formRow">
                                    <div class="col-12">
                                        <inputDatePicker v-model="scheduleDate.start" name="dateStart" :label="isSubscription ? 'Data rozpoczęcia' : 'Data dostawy'" 
                                            :min="minDay" :disabledDates="disabledDates"
                                            @change="changeStartDate()" :error="validation.firstError('scheduleDate.start')" />
                                        <!-- <inputLabel v-model="scheduleDate.end" name="dateEnd" label="Zakończenie dostaw" v-if="isSubscription" /> -->
                                        <div v-if="isSubscription"><span class="pr-4">Zakończenie dostaw</span><strong>{{ scheduleDate.end|formatDate('DD.MM.YYYY') }}</strong></div>
                                    </div>
                                    <div class="col-12 formCell text-center">
                                        <button type="submit" class="btn btn-success">Dalej</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-else>
                            <b-alert variant="success" show>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div v-if="isSubscription">
                                        Rozpoczęcie dostaw: <b>{{ scheduleDate.start|formatDate('DD.MM.YYYY') }}</b>,
                                        Zakończenie dostaw: <b>{{ scheduleDate.end|formatDate('DD.MM.YYYY') }}.</b>
                                    </div>
                                    <div v-else>
                                        Dzień dostawy: <b>{{ scheduleDate.start|formatDate('DD.MM.YYYY') }}</b>
                                    </div>
                                    <button class="btn btn-primary" @click.prevent="changeScheduleDate()">Zmień</button>
                                </div>
                            </b-alert>
                            <div class="container text-center mb-5">
                                <button class="btn btn-lg btn-success" @click.prevent="goToNextStep()">Dalej</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters} from "vuex";
import {SCHEDULE_typeSubscription, orderToNextDayMaxHour } from "@/constants/constants.js"; 

import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

import moment from 'moment';

export default {
    name: "scheduleDateStep",
    components: {},
    data() {
        return {
            isLoading: false,
            scheduleDate : this.value ? this.value : {
                start: this.$store.state.order.schedule.dateStart,
                end: this.$store.state.order.schedule.dateEnd
            },
            minDay: moment().format('HH') < orderToNextDayMaxHour ? moment().add(1,'days').toDate() : moment().add(2,'days').toDate(),
            disabledDates: this.getHolidays(),
        }
    },
    validators: {
        'scheduleDate.start': function (value) {
            return Validator.value(value).required();
        },
    },
    computed: {
        isSubscription( ) {
            if(this.scheduleDate.dateStart == this.scheduleDate.dateEnd && this.scheduleDate.scheduleType == SCHEDULE_typeSubscription) {
                this.changeScheduleDate();
            }
            if(this.scheduleDate.dateStart != this.scheduleDate.dateEnd && this.$store.state.order.schedule.scheduleType != SCHEDULE_typeSubscription) {
                this.changeScheduleDate();
            }
            return this.$store.state.order.schedule.scheduleType == SCHEDULE_typeSubscription
        },
        ...mapGetters({isLoggedIn: 'auth/loggedIn', me : 'auth/me' }),
    },
    mounted(){
        if(!this.isLoggedIn) {
            this.$router.push('/login') 
        }
        this.$store.dispatch('order/loadOrder').then( (data) => {
            this.scheduleDate.end = data.dateEnd;
            this.$store.state.order.schedule.dateEnd = data.dateEnd;
            this.isLoading = false;
        });
    },
    methods: {
        getHolidays() {
            return this.$store.state.order.holidays.map( item => item.date);
        },
        goToNextStep() {
            this.$emit('nextStep', true);
        },
        changeStartDate(){
            if ( this.isSubscription ) {
                this.scheduleDate.end = moment(this.scheduleDate.start).add(1,'months').format('YYYY-MM-DD');
                    // moment(this.scheduleDate.start).format('D') > 1 ? moment(this.scheduleDate.start).add(1,'months').endOf('month').format('YYYY-MM-DD')
                        // : moment(this.scheduleDate.start).endOf('month').format('YYYY-MM-DD') ;
            } else {
                this.scheduleDate.end = moment(this.scheduleDate.start).format('YYYY-MM-DD');
            }
        },
        confirmDate() {
            this.$validate().then( (success) => {
                if (success) {
                    this.isLoading = true;
                    this.$store.dispatch('order/updateDates', this.scheduleDate )
                        .finally( () => {
                            this.isLoading = false;
                            this.$emit('nextStep', true);
                        });
                }
            });
        },
        changeScheduleDate() {
            this.isLoading = true;
            this.$store.dispatch('order/updateDates', { start: null, end: null })
                .finally( () => {
                    this.isLoading = false;
                });
        }
    }
};
</script>
