<template>
    <div class="container">
        <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
        <div>
                <div v-if="showEditForm">
                    <form method="post" @submit.prevent="confirmAddress" action="">
                        <h3>Osoba kontaktowa:</h3> 
                        <div class="row formRow">
                            <div class="col-12 col-md-6 formCell ">
                                <inputText v-model="selectedAddress.name" name="name" label="Imię" :error="validation.firstError('selectedAddress.name')" />
                                <inputText v-model="selectedAddress.surname" name="surname" label="Nazwisko" :error="validation.firstError('selectedAddress.surname')" />
                            </div>
                            <div class="col-12 col-md-6 formCell ">
                                <inputText v-model="selectedAddress.phone" name="phone" label="Numer telefonu" :error="validation.firstError('selectedAddress.phone')" />
                            </div>
                            
                        </div>
                        <h3>Adres dostawy:</h3> 
                    
                        <div class="row formRow">
                            <div class="col-12 col-md-6 formCell ">
                                <inputText v-model="selectedAddress.street" name="street" label="Ulica" :error="validation.firstError('selectedAddress.street')" />
                                <inputText v-model="selectedAddress.number" name="number" label="Numer" :error="validation.firstError('selectedAddress.number')" />
                            </div>
                            <div class="col-12 col-md-6 formCell ">
                                <inputText v-model="selectedAddress.postcode" name="postcode" label="Kod pocztowy" :error="validation.firstError('selectedAddress.postcode')" />
                                <inputText v-model="selectedAddress.city" name="city" label="Miejscowość" :error="validation.firstError('selectedAddress.city')" />
                            </div>
                            <div class="col-12 col-md-6 formCell ">
                                <inputTextarea v-model="selectedAddress.comment" name="comment" label="Uwagi" :error="validation.firstError('selectedAddress.comment')" />
                            </div>

                            <div class="col-12 formCell text-center">
                                <button v-if="addresses.length>0" type="button" class="btn btn-secondary" @click.prevent="cancelEdit()" >Anuluj</button>
                                <button type="submit" class="btn btn-success ml-3" >Zapisz</button>
                            </div>
                        </div>
                        
                    </form> 
                </div>
                <div v-else>
                    <div class="row mb-5" v-if="addresses" >
                        <div class="col-12 col-md-6 col-lg-4 justify-content-md-center" v-for="address in addresses" :key="address.id">
                            <b-card-group deck>
                                <b-card footer-tag="footer">
                                    <b-card-text>
                                        <div>
                                            <b-badge v-if="address.default" variant="success">Domyślny</b-badge>
                                            <b-badge v-else variant="secondary">Dodatkowy</b-badge>
                                        </div>
        
                                        <b>Osoba kontaktowa:</b><br/>
                                        {{ address.name }} {{ address.surname }}<br />
                                        tel. {{ address.phone }} <br />
                                        <br />
                                        <b>Adres:</b><br />
                                        ul. {{ address.street }} {{ address.number }}<br />
                                        {{ address.postcode }} {{ address.city }}<br />
                                        <br />
                                        <b>uwagi:</b><br /> <span class="nl2br" v-html="address.comment"></span>
                                    </b-card-text>
                                    <template #footer>
                                        <center class="row g-2">
                                            <button v-if="!hideSelect" class="btn btn-success m-2" @click.prevent="selectAddress(address)">Wybieram</button>
                                            <button v-if="hideSelect" class="btn btn-secondary m-2" @click.prevent="editAddress(address)">Zmień</button>
                                            <button v-if="hideSelect" class="btn btn-danger m-2" @click.prevent="removeAddress(address)">Usuń</button>
                                            <button v-if="hideSelect && !address.default" class="btn btn-success m-2" @click.prevent="setDefaultAddress(address)">Ustaw jako domyślny</button>
                                        </center>
                                    </template>
                                </b-card>
                            </b-card-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <center>
                                <button class="btn btn-secondary mt-4" @click.prevent="addAddress()">Dodaj nowy adres</button>
                            </center>
                        </div>
                    </div>
                </div>
            <!--
            <div v-if="! $store.state.order.completedStepAddress">
            </div>
            <div v-else>
                <b-alert variant="success" show>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <div>
                                Osoba kontaktowa: <b>{{ selectedAddress.name }} {{ selectedAddress.surname }}</b><br />
                                tel. <b>{{ selectedAddress.phone }}</b><br />
                                Adres dostawy: 
                                <b>ul. {{ selectedAddress.street }} {{ selectedAddress.number }},
                                {{ selectedAddress.postcode }} {{ selectedAddress.city }}</b>
                                <br />
                                
                            </div>
                            <div v-if="selectedAddress.comment" class="d-flex justify-content-between" >
                                <div>uwagi:</div> 
                                <div><b><span class="nl2br" v-html="selectedAddress.comment"></span></b></div>
                            </div>
                        </div>
                        <button class="btn btn-primary" @click.prevent="changeAddress()">Zmień adres</button>
                    </div>
                </b-alert>

                <div class="container text-center mb-5">
                    <button class="btn btn-lg btn-success" @click.prevent="goToNextStep()">Dalej</button>
                </div>
            </div>
            -->
        </div>
    </div>
</template>

<script>
import { mapGetters} from "vuex";

import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

export default {
    name: "addressStep",
    props: {
        hideSelect: {
            type:Boolean,
            required: false,
            default: false
        }
    },  
    data() {
        return {
            isLoading: true,
            addresses: [],
            showEditForm: false,
            showSelectedAddress: this.value ? true : false,
            selectedAddress: this.$store.state.order.schedule.address
        }
    },
    mounted(){
        if(!this.isLoggedIn) {
            this.$router.push('/login') 
        }
        this.$store.dispatch('customer/loadAddresses').then( (data) => {
            this.addresses = data;
            
            if ( this.addresses.length == 0 ) 
                this.addAddress();

            if ( !this.hideSelect && ! this.selectedAddress) {
                this.selectedAddress = this.addresses.find(item => item.default == true);
                if ( this.selectedAddress ) {
                    this.isLoading = true;
                    this.$store.dispatch('order/updateAddress', this.selectedAddress)
                        .finally( () => {
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = false;
                }
            } else {
                this.isLoading = false;
            }

        });
    },
    validators: {
        'selectedAddress.name': function (value) {
            return Validator.value(value).required().maxLength(255);
        },
        'selectedAddress.surname': function (value) {
            return Validator.value(value).required().maxLength(255);
        },        
        'selectedAddress.postcode': {
            cache: 'last',
            debounce: 200,
            validator: function (value) {
                let vMessage = Validator.value(value)
                    .required()
                    .regex('^[0-9]{2}-[0-9]{3}$', 'Podaj poprawny kod pocztowy');
                if ( vMessage._messages.length == 0 ) {
                    vMessage = Validator.custom(function () {  
                        if (!Validator.isEmpty(value)) {
                            return this.$store.dispatch('auth/checkCitySupported', { code: this.selectedAddress.postcode }  )
                                .catch( ( error ) =>  {
                                    return error;
                                });
                        }
                    }.bind(this) );
                }
                return vMessage;
            }
        },
        'selectedAddress.city': function (value) {
            return Validator.value(value).required().maxLength(255);
        },
        'selectedAddress.street': function (value) {
            return Validator.value(value).required().maxLength(255);
        },
        'selectedAddress.number': function (value) {
            return Validator.value(value).required().maxLength(255);
        },
        'selectedAddress.phone': function (value) {
            return Validator.value(value).required().maxLength(255);
        },
        'selectedAddress.comment': function (value) {
            if ( value )
            return Validator.value(value).maxLength(500);
        },

    },
    computed: {
        ...mapGetters({isLoggedIn: 'auth/loggedIn', me : 'auth/me' }),
    },
    methods: {
        goToNextStep() {
            this.$emit('nextStep', true);
        },
        confirmAddress() {
            this.$validate().then( (success) => {
                if (success) {
                    this.showEditForm = false; 
                    this.showSelectedAddress = true;
                    
                    this.$store.dispatch('customer/saveAddress', this.selectedAddress)
                    .then( () => {
                        this.addresses = this.$store.getters['customer/addresses'];
                        // dedirect to next step if only one address
                        if(this.addresses.length == 1) {
                            this.setDefaultAddress(this.addresses[0]);
                            this.selectAddress(this.addresses[0]);
                            this.$emit('nextStep', true);
                        }
                        this.$emit('input', this.selectedAddress);
                    });
                }
            });
        },
        setDefaultAddress(address) {
            this.isLoading = true;
            this.$store.dispatch('customer/setDefaultAddress', address)
                .finally( () => {
                    this.addresses = this.$store.getters['customer/addresses'];
                    this.isLoading = false;
                });
        },
        cancelEdit() {
            this.isLoading = true;
            this.$store.dispatch('customer/loadAddresses').then( (data) => {
                this.addresses = data;
                this.showEditForm = false;
                this.isLoading = false;
            });
        },
        addAddress() {
            this.selectedAddress = { 
                id: null,
                name: this.me.name,
                surname: this.me.surname,
                street: '',
                number: '',
                postcode: '',
                city: '',
                phone: this.me.phone,
                comment: '',
            };
            this.showEditForm = true; 
            this.showSelectedAddress = false;
        },
        changeAddress() {
            this.showSelectedAddress = false;
            this.showEditForm = false;

            this.isLoading = true;
            this.$store.dispatch('order/updateAddress', null)
                .finally( () => {
                    this.isLoading = false;
                });
        },
        selectAddress(address){
            this.showEditForm = false; 
            this.selectedAddress = address;
            this.showSelectedAddress = true;

            this.isLoading = true;
            this.$store.dispatch('order/updateAddress', this.selectedAddress)
                .finally( () => {
                    this.isLoading = false;
                    this.$emit('nextStep', true);
                });
        },
        editAddress(address){
            this.showEditForm = true; 
            this.showSelectedAddress = false;
            this.selectedAddress = address;
            this.$emit('input', null);
        },
        confirmRemoveAddress(address){
            this.isLoading = true;
            this.$store.dispatch('customer/removeAddress', address.id)
                .finally( () => {

                    this.$store.dispatch('customer/loadAddresses').then( (data) => {
                        this.addresses = data;

                        if ( this.addresses.length == 0 )
                            this.addAddress();

                        this.isLoading = false;
                    });

                });
        },
        removeAddress(address) {
            this.$bvModal
                .msgBoxConfirm('Na pewno chcesz wykasować ten adres?', {
                    title: '',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Tak',
                    cancelTitle: 'Nie',
                    footerClass: 'p-2',
                    hideHeaderClose: true,
                    centered: true
                })
                .then(value => {
                    if(value)
                        this.confirmRemoveAddress(address)
                })
            .catch(err => {
                console.log(err)
            })
      }
    }
};
</script>
