<template>
    <div class="container">
        <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
        <div v-if="!isLoading && order">
            
            <orderDetails :order="order" />

            <div v-if="order.deliveryPriceDiscount >= 1">
                <h4 class="mt-5 text-right">Suma: <span class="product-sum">{{ Number(Number(order.sumPrice) + Number(order.deliveryPriceDiscount)).toLocaleString() }} zł</span></h4>
                <div class="mt-1 text-right text-success" v-if="order.deliveryDiscountInfo">{{ order.deliveryDiscountInfo }}</div>
                <h4 class="mt-1 text-right">Rabat: <span class="product-sum">{{ Number(order.deliveryPriceDiscount).toLocaleString() }} zł</span></h4>
            </div>
            <h3 class="mt-5 text-right">Razem do zapłaty: <span class="product-sum">{{ Number(order.sumPrice).toLocaleString() }} zł</span></h3>
            <div class="text-center mt-3 mb-5">
                <button class="btn btn-lg btn-success" @click.prevent="goToPayment()" >Potwierdzam i płacę</button>
            </div>           
            
            <div class="form-row align-items-center my-5 -d-none">
                <div class="col-auto btn-group">
                    <input v-model="promotionCode" name="promotionCode" label="Kupon promocyjny" placeholder="Kupon rabatowy" @change="changePromotionCode()" class="form-control mb-2" />
                    <input type="buton" name="promotionCodeUse" label="Zastosuj" value="Zastosuj" class="mb-2 btn btn-warning" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import { mapGetters} from "vuex";
import OrderDetails from '@/components/order/OrderDetails.vue';

export default {
    name: "summaryStep",
    components: {
        OrderDetails
    },
    data() {
        return {
            isLoading: true,
            order: null,
            promotionCode: this.$store.state.promotionCode != '' ? this.$store.state.promotionCode : this.value ,
        }
    },
    computed: {
        ...mapGetters({isLoggedIn: 'auth/loggedIn', me : 'auth/me' }),
    },
    mounted(){
        if(!this.isLoggedIn) {
            this.$router.push('/login') 
        }
        this.$store.dispatch('order/loadOrder').then( (data) => {
            this.order = data;
            this.isLoading = false;
            this.promotionCode = this.promotionCode ? this.promotionCode : data.code;
        });
    },
    methods: {
        goToPayment() {
            this.isLoading = true;

            this.$gtag.event('purchase', {
                'currency': 'PLN',
                // 'items': [
                //     {
                //     "id": "P12345",
                //     "name": "Android Warhol T-Shirt",
                //     "list_name": "Search Results",
                //     "brand": "Google",
                //     "category": "Apparel/T-Shirts",
                //     "variant": "Black",
                //     "list_position": 1,
                //     "quantity": 2,
                //     "price": 2
                //     },
                // ],
                'transaction_id': this.order.orderToken,
                'shipping': Number(this.order.deliveryPrice).toLocaleString().replace(',','.'), 
                'tax': '0.00',
                'value': Number(this.order.sumPrice).toLocaleString().replace(',','.')
            });

            this.$analytics.fbq.event('Purchase', {
                currency: "PLN", 
                value: this.order.sumPrice
            });
            
            console.log('Purchase event');

            //TODO - sprawdzić czy data nie nachodzi na orderToNextDayMaxHour
            this.$store.dispatch('order/setReady').then( (orderId) => {
                this.$router.push( {name: 'OrderPayment', params: { orderId: orderId } } );
            });
        },        
        changePromotionCode() {        
            this.$store.state.promotionCode = this.promotionCode;
            this.order = null;
            this.isLoading = true;
            this.$store.dispatch('order/updatePromotionCode',this.promotionCode).then( (data) => {
                this.order = data;
                this.isLoading = false;
                this.promotionCode = this.promotionCode ? this.promotionCode : data.code;
            });
        },
    }
};
</script>