<template>

    <b-card header-tag="header" style="height:100%; position: relative">
        <template #header>
            <h6 class="text-center mb-0">{{ product.name }}</h6>
        </template>
        <b-card-text>
            <div class="text-center mb-3">
                <img :src="getImageUrl(product.image)" style="max-width:60%" :alt="product.name" />
            </div>
            <div>Cena: {{ Number(product.price).toLocaleString() }} zł</div>
            <div>Waga: {{ Number(product.weight).toLocaleString() }} kg</div>
            <!-- <div>Opis: <span v-html="product.description"></span></div> -->
            <!-- <div>Skład: <span v-html="product.ingredients"></span></div> -->
        </b-card-text>
        <center>            
            <b-button variant="secondary" @click.prevent="addProduct(product)" v-if="!productSelected">Dodaj do koszyka</b-button>
            <router-link class="text-success" v-if="productSelected" :to="{name: 'OrderAdd'}">Dodano do koszyka</router-link>
        </center>
    </b-card>
</template>

<script>
export default {
    name: "productInfo",
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            productSelected: false
        }
    },
    mounted(){
        this.isProductSelected();
    },
    methods: {
        getImageUrl(image) {
            return process.env.VUE_APP_BACKEND_IMAGES + '/' + image;
        },
        isProductSelected() {
           this.productSelected = (this.$store.state.order.schedule.productTemplates.filter(item => item.product_id == this.product.id)).length > 0;
        },
        addProduct(product){

            const productTemplate = { 
                ...product,
                product_id: product.id,
                amount: {
                    sunday: 0,
                    monday: 0,
                    tuesday: 0,
                    wednesday: 0,
                    thursday: 0,
                    friday: 0,
                    saturday: 0,
                },
                date_amount: 0,
                isValid: false
            };

            this.$store.dispatch('order/loadBasket')
                .then( () => {
                    this.$store.dispatch('order/updateProductTemplate', productTemplate)
                        .then( () => {
                            // const vNodesMsg = this.$createElement( 'a', { attrs: { href: ['/order/add'] } }, 'przejdź do koszyka' );

                            // this.$bvToast.toast( [vNodesMsg], { 
                            //     title: 'dodano do koszyka ' + product.name,
                            //     autoHideDelay: 1000,
                            //     appendToast: true,
                            //     solid: true,
                            //     variant: 'warning',
                            //     toaster: 'b-toaster-bottom-center'
                            // })
                            this.isProductSelected();
                        });
                });

            this.$analytics.fbq.event('track','AddToCart');

            // TODO 
            // this.$analytics.fbq('track','AddToCart',
            // {
            //     content_name: 'Really Fast Running Shoes', 
            //     content_category: 'Apparel & Accessories > Shoes',
            //     content_ids: ['1234'],
            //     content_type: 'product',
            //     value: 4.99,
            //     currency: 'USD' 
            // });
        },
    }
};
</script>
