<template>
    <b-card header-tag="header"  footer-tag="footer" >
        <b-modal ref="modalProductInfo" :title="product.name" ok-only centered scrollable ok-title="Zamknij" hide-footer>
            <template v-slot:default="">
                <div class="text-center mb-3">
                    <img :src="getImageUrl(product.image)" style="max-width:60%" />
                </div>
                <div>Cena: {{ Number(product.price).toLocaleString() }} zł</div>
                <div>Waga: {{ Number(product.weight).toLocaleString() }} kg </div>
                <div>Opis: <span v-html="product.description"></span></div>
                <div>Skład: <span v-html="product.ingredients"></span></div>
            </template>
        </b-modal>
        <template #header>
            <div class="mb-1 m-3 align-center">
                <img :src="getImageUrl(product.image)"  @click.prevent="showInfo()"/>
            </div>
            <h5 class="text-center mb-0">{{ product.name }}</h5>
        </template>

        <b-card-text>
            <div class="d-flex justify-content-between">            
                <div>{{ Number(product.price).toLocaleString() }} zł / szt.</div>
                <div>{{ Number(product.weight).toLocaleString() }} kg</div>
            </div>
        </b-card-text>

        <slot/>

        <template #footer >
            <div class="text-center">
                <slot name="footer" />
            </div>
        </template>
    </b-card>
</template>

<script>

export default {
    name: "productShortInfo",
    props: {
        product: {
            type: Object,
            required: true
        },
    },
    methods: {
        getImageUrl(image) {
            return process.env.VUE_APP_BACKEND_IMAGES + '/' + image;
        },
        showInfo(){
            this.$refs.modalProductInfo.show();
        }
    }
};
</script>
