<template>
    <div class="container">


        <b-modal ref="modalAddProduct" size="xl" title="Wybierz produkt" ok-only centered ok-title="Zamknij" hide-footer>
            <template v-slot:default="">
                <div class="row justify-content-md-center">
                    <a v-for="product in productsToAdd" :key="product.id" href="#" @click.prevent="addProduct(product)" class="product-on-list col-12 col-md-6 col-xl-3">
                        <productInfo :product="product" />
                    </a>
                </div>
            </template>
        </b-modal>

        <div class="row">
            <div v-for="productTemplate in selectedProductTemplates" :key="productTemplate.product_id" :class="{error: !productTemplate.isValid }" class="product-on-list col-12 col-md-6 col-xl-3">
                <productShortInfo :product="productTemplate"  style="height:100%; position: relative">
                    <div class="text-center mb-2"><strong>Podaj ilość:</strong></div>
                    <div v-if="isSubscription" >
                        <div class="row">
                            <div class="col-4 text-right">Pn</div>
                            <div class="col-8 text-left" v-if="(productTemplate.avilible.monday==1)">
                                <button v-on:click="productTemplate.amount.monday = parseInt(productTemplate.amount.monday) - (productTemplate.amount.monday>0 ? 1 : 0); saveChange(productTemplate)">-</button>
                                <input type="number" v-model="productTemplate.amount.monday" class="inputAmount" min="0" @change="saveChange(productTemplate)">
                                <button v-on:click="productTemplate.amount.monday = parseInt(productTemplate.amount.monday) + 1; saveChange(productTemplate)">+</button>
                            </div>
                            <div v-else class="col-8 text-left">
                                <span>Niedostępny</span>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-4 text-right">Wt</div>
                            <div class="col-8 text-left" v-if="(productTemplate.avilible.tuesday==1)">
                                <button v-on:click="productTemplate.amount.tuesday = parseInt(productTemplate.amount.tuesday) - (productTemplate.amount.tuesday>0 ? 1 : 0); saveChange(productTemplate)">-</button>
                                <input type="number" v-model="productTemplate.amount.tuesday" class="inputAmount" min="0" @change="saveChange(productTemplate)">
                                <button v-on:click="productTemplate.amount.tuesday = parseInt(productTemplate.amount.tuesday) + 1; saveChange(productTemplate)">+</button>
                            </div>
                            <div v-else class="col-8 text-left">
                                <span>Niedostępny</span>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-4 text-right">Śr</div>
                            <div class="col-8 text-left" v-if="(productTemplate.avilible.wednesday==1)">
                                <button v-on:click="productTemplate.amount.wednesday = parseInt(productTemplate.amount.wednesday) - (productTemplate.amount.wednesday>0 ? 1 : 0); saveChange(productTemplate)">-</button>
                                <input type="number" v-model="productTemplate.amount.wednesday" class="inputAmount" min="0" @change="saveChange(productTemplate)">
                                <button v-on:click="productTemplate.amount.wednesday = parseInt(productTemplate.amount.wednesday) + 1; saveChange(productTemplate)">+</button>
                            </div>
                            <div v-else class="col-8 text-left">
                                <span>Niedostępny</span>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-4 text-right">Czw</div>
                            <div class="col-6 text-left" v-if="(productTemplate.avilible.thursday==1)">
                                <button v-on:click="productTemplate.amount.thursday = parseInt(productTemplate.amount.thursday) - (productTemplate.amount.thursday>0 ? 1 : 0); saveChange(productTemplate)">-</button>
                                <input type="number" v-model="productTemplate.amount.thursday" class="inputAmount" min="0" @change="saveChange(productTemplate)">
                                <button v-on:click="productTemplate.amount.thursday = parseInt(productTemplate.amount.thursday) + 1; saveChange(productTemplate)">+</button>
                            </div>
                            <div v-else class="col-8 text-left">
                                <span>Niedostępny</span>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-4 text-right">Pt</div>
                            <div class="col-6 text-left" v-if="(productTemplate.avilible.friday==1)">
                                <button v-on:click="productTemplate.amount.friday = parseInt(productTemplate.amount.friday) - (productTemplate.amount.friday>0 ? 1 : 0); saveChange(productTemplate)">-</button>
                                <input type="number" v-model="productTemplate.amount.friday" class="inputAmount" min="0" @change="saveChange(productTemplate)">
                                <button v-on:click="productTemplate.amount.friday = parseInt(productTemplate.amount.friday) + 1; saveChange(productTemplate)">+</button>
                            </div>
                            <div v-else class="col-8 text-left">
                                <span>Niedostępny</span>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-4 text-right">Sb</div>
                            <div class="col-6 text-left" v-if="(productTemplate.avilible.saturday==1)">
                                <button v-on:click="productTemplate.amount.saturday = parseInt(productTemplate.amount.saturday) - (productTemplate.amount.saturday>0 ? 1 : 0); saveChange(productTemplate)">-</button>
                                <input type="number" v-model="productTemplate.amount.saturday" class="inputAmount" min="0" @change="saveChange(productTemplate)">
                                <button v-on:click="productTemplate.amount.saturday = parseInt(productTemplate.amount.saturday) + 1; saveChange(productTemplate)">+</button>
                            </div>
                            <div v-else class="col-8 text-left">
                                <span>Niedostępny</span>
                            </div>
                        </div>
                    </div>
                    <div name="inner" v-else >
                        <div class="row">
                            <div class="col-6 text-left" v-if="(productTemplate.avilible.friday==1)">
                                <button v-on:click="productTemplate.date_amount = parseInt(productTemplate.date_amount) - (productTemplate.date_amount>0 ? 1 : 0); saveChange(productTemplate)">-</button>
                                <input type="number" v-model="productTemplate.date_amount" class="inputAmount" min="0" @change="saveChange(productTemplate)">
                                <button v-on:click="productTemplate.date_amount = parseInt(productTemplate.date_amount) + 1; saveChange(productTemplate)">+</button>
                            </div>
                        </div>
                    </div>

                    <center v-if="productTemplate.isValid == false">
                        <b-badge variant="danger" >Wpisz ilość produktów</b-badge>
                    </center>

                    <template v-slot:footer> 
                        <button type="text" class="btn btn-primary" @click="removeProductTemplate(productTemplate)" >Usuń</button>
                    </template>
                </productShortInfo>
                
            </div>
            <div class="product-on-list col-12 col-md-6 col-xl-3" v-if="canAddNextProduct">
                <b-card>
                    <b-card-text class="text-center">
                        <button  type="text" class="btn btn-secondary my-5"  @click="addAnotherProduct" >Dodaj produkt</button>
                    </b-card-text>
                </b-card>
            </div>

        </div>
        
    </div>
</template>

<script>

import ProductInfo from '@/components/partial/ProductInfo.vue';
import ProductShortInfo from '@/components/partial/ProductShortInfo.vue';

export default {
    name: "selectProducts",
    components: {
        ProductInfo,
        ProductShortInfo
    },
    props: {
        products: {
            type: Array,
            required: true
        },
        group: {
            type: Number,
            required: true
        },
        isSubscription: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            editing: true,
            canAddNextProduct: true,
            selectedProductTemplates: this.$store.state.order.schedule.productTemplates.filter( item => item.group == this.group),
            productsToAdd: [],
        }
    },
    mounted(){
        this.setProductsToAdd();
    },
    methods: {
        setProductsToAdd() {
            const ids = this.selectedProductTemplates.map( item => item.product_id);
            this.validateProducts();
            this.productsToAdd = this.products.filter( item =>  !ids.includes( item.id ) );
            this.canAddNextProduct = this.productsToAdd.length == 0 ? false :  true;
        },
        validateProducts() { 
            for (var i = 0; i < this.selectedProductTemplates.length; i++)
            {
                if(this.isSubscription) {
                    this.selectedProductTemplates[i].isValid = (this.selectedProductTemplates[i].amount.sunday>0 || this.selectedProductTemplates[i].amount.monday>0 ||this.selectedProductTemplates[i].amount.tuesday>0 || this.selectedProductTemplates[i].amount.wednesday>0 || this.selectedProductTemplates[i].amount.thursday>0 || this.selectedProductTemplates[i].amount.friday>0 || this.selectedProductTemplates[i].amount.saturday>0) ? true : false;
                } else {
                    this.selectedProductTemplates[i].isValid = this.selectedProductTemplates[i].date_amount>0 ? true : false;
                }
            }
        },
        saveChange(productTemplate) {
            this.$store.dispatch('order/updateProductTemplate', productTemplate).then( (pT) => {
                const index = this.selectedProductTemplates.findIndex( item => item.product_id == pT.product_id );
                if ( index > -1 ) {
                    this.selectedProductTemplates[index] = pT;
                }

                this.$forceUpdate();
            });
        },
        addProduct(product){
            if ( this.selectedProductTemplates.findIndex( p=> p.product_id == product.id ) == -1 ) {
                const productTemplate = { 
                    ...product,
                    product_id: product.id,
                    amount: {
                        sunday: 0,
                        monday: 0,
                        tuesday: 0,
                        wednesday: 0,
                        thursday: 0,
                        friday: 0,
                        saturday: 0,
                    },
                    avilible: {
                        sunday: 0,
                        monday: 1,
                        tuesday: 1,
                        wednesday: 1,
                        thursday: 1,
                        friday: 1,
                        saturday: 1,
                    },
                    date_amount: 0,
                    isValid: false
                };

                //abonament
                if(product.amount) {
                    productTemplate.amount =  {
                        sunday: product.amount.sunday>0 ? product.amount.sunday : 0,
                        monday: product.amount.monday>0 ? product.amount.monday : 0,
                        tuesday: product.amount.tuesday>0 ? product.amount.tuesday : 0,
                        wednesday: product.amount.wednesday>0 ? product.amount.wednesday : 0,
                        thursday: product.amount.thursday>0 ? product.amount.thursday : 0,
                        friday: product.amount.friday>0 ? product.amount.friday : 0,
                        saturday: product.amount.saturday>0 ? product.amount.saturday : 0
                    };
                    // productTemplate.isValid = this.isSubscription == true && (product.amount.sunday>0 || product.amount.monday>0 ||product.amount.tuesday>0 || product.amount.wednesday>0 || product.amount.thursday>0 || product.amount.friday>0 || product.amount.saturday>0) ? true: false;
                }

                //jednorazowy
                if(product.date_amount) {
                    productTemplate.date_amount= product.date_amount>0 ? product.date_amount : 0;
                    // productTemplate.isValid = this.isSubscription == null && product.date_amount>0 ? true : false;
                }

                this.$store.dispatch('order/updateProductTemplate', productTemplate)
                    .then( (pT) => {
                        this.selectedProductTemplates.push( pT );
                        this.setProductsToAdd();
                    });
            }
            
            this.$refs.modalAddProduct.hide();
        },
        addAnotherProduct() {
            this.$refs.modalAddProduct.show();
        },
        removeProductTemplate(productTemplate){
            this.$store.dispatch('order/removeProductTemplate', productTemplate)
                .then( (productId) => {
                    this.selectedProductTemplates = this.selectedProductTemplates.filter(item => item.product_id != productId);
                    this.setProductsToAdd();
                });

        },
        stopEditing(){
            this.editing = false;
        },

    }
};
</script>
