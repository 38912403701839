<template>
    <div class="container">
        <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
        <div v-else >
            <div v-if="!$store.state.order.completedStepUser && !isLoggedIn">
                <div class="row justify-content-md-center mb-5">
                    <div class="col-12 col-md-6 text-center">
                        <router-link :to="{name: 'Login'}"><button class="btn btn-success m-2" >Zaloguj</button></router-link>
                        <router-link :to="{name: 'Register'}"><button class="btn btn-success m-2" >Rejestracja</button></router-link>
                    </div>
                </div>
            </div>
            <div v-else>
                <b-alert variant="success" show>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            Imię: <b>{{ me.name}}</b><br />
                            Nazwisko: <b>{{ me.surname }}</b><br />
                            Email: <b>{{ me.email }}</b><br />
                            Telefon: <b>{{ me.phone }}</b><br />
                        </div>
                        <router-link :to="{name: 'CustomerChangeData'}"><button class="btn btn-secondary" >Zmień</button></router-link>
                    </div>
                </b-alert>
                <div class="container text-center mb-5">
                    <button class="btn btn-lg btn-success" @click.prevent="goToNextStep()">Dalej</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters} from "vuex";

export default {
    name: "userStep",
    components: {},
    data() {
        return {
            isLoading: false
        }
    },
    mounted(){
        if(!this.isLoggedIn) {
            this.$router.push('/login') 
        }
    },
    computed: {
        ...mapGetters({isLoggedIn: 'auth/loggedIn', me : 'auth/me' }),
    },
    methods: {
        goToNextStep() {
            this.$emit('nextStep', true);
        },
    }
};
</script>
