<template>
    <div class="order-page my-5">
        <div class="container mb-5">
            <div class="row orderSteps" v-if="currentStep > 1">               
                <!-- <div class="col-2">
                    <a href="#" @click.prevent="setStep(1, true )"
                        :class="{ disabled: !$store.state.order.completedStepScheduleType }" >
                        <span class="stepCircle" :class="{active: $store.state.order.completedStepScheduleType }">2</span> 
                        <span class="stepLabel" :class="{activeStep: currentStep == 1}">TWOJE DANE</span>
                    </a>
                </div> -->
                <div class="col-2">
                    <a href="#" @click.prevent="setStep(2, $store.state.order.completedStepUser )" >
                        <span class="stepCircle" :class="{active: $store.state.order.completedStepScheduleType, activeCircleStep: currentStep == 2}" >1</span> 
                        <span class="stepLabel" :class="{activeStep: currentStep == 2}">RODZAJ DOSTAWY</span>
                    </a>
                </div>
                <div class="col-2">
                    <a href="#" @click.prevent="setStep(3, $store.state.order.completedStepScheduleType )"
                        :class="{ disabled: !$store.state.order.completedStepUser }" >
                        <span class="stepCircle" :class="{active: $store.state.order.completedStepScheduleType && $store.state.order.completedStepUser, activeCircleStep: currentStep == 3}">2</span> 
                        <span class="stepLabel" :class="{activeStep: currentStep == 3}">ADRES</span>
                    </a>
                </div>
                <div class="col-2" >
                    <a href="#" @click.prevent="setStep(4, $store.state.order.completedStepAddress )"
                        :class="{ disabled: !$store.state.order.completedStepAddress }" >
                        <span class="stepCircle" :class="{active: $store.state.order.completedStepAddress, activeCircleStep: currentStep == 4}">3</span> 
                        <span class="stepLabel" :class="{activeStep: currentStep == 4}">PRODUKTY</span>
                    </a>
                </div>
                <div class="col-2">
                    <a href="#" @click.prevent="setStep(5, $store.state.order.completedStepProducts )"
                        :class="{ disabled: !$store.state.order.completedStepProducts}" >
                        <span class="stepCircle" :class="{active: $store.state.order.completedStepProducts, activeCircleStep: currentStep == 5}" >4</span> 
                        <span class="stepLabel" :class="{activeStep: currentStep == 5}">TERMIN DOSTAWY</span>
                    </a>
                </div>
                <div class="col-2">
                    <a href="#" @click.prevent="setStep(6, completedAllSteps )"
                        :class="{ disabled: !completedAllSteps }" >
                        <span class="stepCircle" :class="{active: completedAllSteps, activeCircleStep: currentStep == 6}" >5</span> 
                        <span class="stepLabel" :class="{activeStep: currentStep == 6}">PODSUMOWANIE</span>
                    </a>
                </div>
            </div>
        </div>

        <userStep v-if="currentStep == 1" @nextStep="goToNextStep()" />
        <scheduleTypeStep v-if="currentStep == 2" @nextStep="goToNextStep()" />
        <addressStep v-if="currentStep == 3" @nextStep="goToNextStep()" />
        <productsStep v-if="currentStep == 4" @nextStep="goToNextStep()" />
        <scheduleDateStep v-if="currentStep == 5" @nextStep="goToNextStep()" />
        <summaryStep v-if="currentStep == 6"  />
    </div>
</template>

<script>

import ScheduleTypeStep from '@/components/order/ScheduleTypeStep.vue';
import UserStep from '@/components/order/UserStep.vue';
import AddressStep from '@/components/order/AddressStep.vue';
import ProductsStep from '@/components/order/ProductsStep.vue';
import ScheduleDateStep from '@/components/order/ScheduleDateStep.vue';
import SummaryStep from '@/components/order/SummaryStep.vue';

import { mapActions } from "vuex";

export default {
    name: "Order",
    components: {
        ScheduleTypeStep,
        UserStep,
        AddressStep,
        ProductsStep,
        ScheduleDateStep,
        SummaryStep
    },
    mounted(){
        this.loadBasket().finally( () => {
            this.currentStep = this.startStep()
        });
    },
    data() {
        return {
            currentStep: 0,
            address: null,
            scheduleDate: null,
            summary: false
        }
    },
    computed: {
        completedAllSteps() {
            return this.$store.state.order.completedStepDate && this.$store.state.order.completedStepProducts
                 && this.$store.state.order.completedStepAddress && this.$store.state.order.completedStepScheduleType
                 && this.$store.state.order.completedStepUser;
        }
    }, 
    methods: {
        ...mapActions({ loadBasket: 'order/loadBasket' }),
        startStep(){
            let step = 1;
            if ( this.$store.state.order.completedStepDate && this.$store.state.order.completedStepProducts
                 && this.$store.state.order.completedStepAddress && this.$store.state.order.completedStepScheduleType
                 && this.$store.state.order.completedStepUser )
                step = 6;
            else if ( this.$store.state.order.completedStepProducts && this.$store.state.order.completedStepAddress 
                && this.$store.state.order.completedStepScheduleType && this.$store.state.order.completedStepUser )
                step = 5;
            else if ( this.$store.state.order.completedStepAddress && this.$store.state.order.completedStepScheduleType 
                && this.$store.state.order.completedStepUser)
                step =  4;
            else if ( this.$store.state.order.completedStepScheduleType && this.$store.state.order.completedStepUser)
                step = 3;
            else if ( this.$store.state.order.completedStepUser )
                step = 2;

            return step;
        },
        setStep(step, canChange) {
            if ( canChange ) {
                this.currentStep = step;
            }
        },
        goToNextStep() {
            this.currentStep++;
        },
        
    }
};
</script>
